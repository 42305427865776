<template>
    <a-layout>
        <a-layout-content>
            <a-tabs default-active-key="1">
                <template slot="tabBarExtraContent">
                    <a-input-search placeholder="请输入查询内容" v-model="keyword" style="width: 200px" @search="onSearch" />
                    <!-- <a-button type="primary" size="small" class="add-article" @click="editVisible=true">
                        <span class="iconfont icon-piliangtianjia"></span>新增原文
                    </a-button> -->
                    <!-- <a-button type="primary" size="small" class="del-article" @click="multiDelete">
                        <span class="iconfont icon-shanchu"></span>删除原文
                    </a-button> -->
                </template>
                <a-tab-pane key="1" tab="原文查看"></a-tab-pane>
            </a-tabs>
            <div class="table-wrap">
                <!-- <a-table :pagination="pageSetting" :rowKey="(r, i) => i" :columns="allColumns" @change="changeTable" :data-source="articles" :row-selection="rowSelection"> -->
                <a-table :pagination="pageSetting" :rowKey="(r, i) => i" :columns="allColumns" @change="changeTable" :data-source="articles">
                        <template slot="operation" slot-scope="text,record">
                            <!-- <a-button type="link" @click="edit(record)">编辑</a-button>
                            <a-button type="link" @click="deleteArticle(record.autoId)">删除</a-button> -->
                            <a-button type="link" @click="downloadFile(record.autoId)">下载</a-button>
                        </template>
                        <template slot="memoDesc" slot-scope="text">
                            <span v-html="text"></span>
                        </template>
                </a-table>
            </div>

            <a-modal 
                :title="waitingEdit?'编辑原文':'新增原文'"
                :visible="editVisible"
                centered
                :footer="null"
                :width="1082"
                wrapClassName="edit-cube-win"
                @cancel="cancelEdit">

                <div class="inputs">
                    <a-row :gutter="[16,16]">
                        <a-col :span="24">
                            <a-row type="flex">
                                <a-col flex="100px" class="label">文件名称</a-col>
                                <a-col flex="auto">
                                    <a-input v-model="fileName" placeholder="请输入文件名称"/>
                                </a-col>
                            </a-row>
                        </a-col>
                    </a-row>                    
                    <a-row type="flex" :gutter="[16,32]">
                        <a-col flex="100px">原文维度</a-col>
                        <a-col flex="200px">
                            <a-select
                            style="width: 100%"
                            placeholder="选择数据库"
                            v-model="cubeName"
                            >
                                <a-select-option @click="handleChangeCube(item.cubeId)" :value="item.cubeNameZh" v-for="item in userInfo.metaCubeList" :key="item.cubeId">
                                {{item.cubeNameZh}}
                                </a-select-option>
                            </a-select>
                        </a-col>
                        <a-col flex="250px">
                            <div>
                                <a-dropdown :trigger="['click']" :disabled="!cubeId" :class="{'disabledStyle':!cubeId}">
                                <div class="ant-dropdown-link" @click.prevent="selectIndicator">
                                    <span v-if="!selectedIndicatorList.length">选择指标</span>
                                    <span v-else style="color:#595959">已选择指标（{{selectedIndicatorList.length}}）</span>
                                    <a-icon type="down" />
                                </div>
                                <div slot="overlay" class="regionList">
                                        <!-- <div class="dim-search" @click.stop>
                                            <a-input-search size="small" />
                                        </div> -->
                                        <div class="dim-scroll" @click.stop>
                                            <vue-scroll :ops="ops" style="height:100%">
                                                <a-tree
                                                        v-model="checkedKeysIndicator"
                                                        checkable
                                                        :tree-data="IndicatorList"
                                                        :replaceFields="{title:'dimName',key:'dimCode',children:'children'}"
                                                    />
                                            </vue-scroll>
                                        </div>
                                    </div>
                                </a-dropdown>
                            </div>
                        </a-col>
                        <a-col flex="250px">
                            <div>
                                <a-dropdown :trigger="['click']" :disabled="!cubeId" :class="{'disabledStyle':!cubeId}">
                                <div class="ant-dropdown-link" @click.prevent="selectArea">
                                    <span v-if="!selectedAreaList.length">选择地区</span>
                                    <span v-else style="color:#595959">已选择地区（{{selectedAreaList.length}}）</span>
                                    <a-icon type="down" />
                                </div>
                                <div slot="overlay" class="regionList">
                                        <!-- <div class="dim-search" @click.stop>
                                            <a-input-search size="small" />
                                        </div> -->
                                        <div class="dim-scroll" @click.stop>
                                            <vue-scroll :ops="ops" style="height:100%">
                                                <a-tree
                                                        v-model="checkedKeysArea"
                                                        checkable
                                                        checkStrictly
                                                        :load-data="e=>{return onLoadData(e,'REGION')}"                                                        
                                                        :tree-data="areaList"
                                                        :replaceFields="{title:'dimName',key:'dimCode',children:'children'}"
                                                    />
                                            </vue-scroll>
                                        </div>
                                    </div>
                                </a-dropdown>
                            </div>
                        </a-col>
                        <a-col flex="250px">
                            <div>
                                <a-dropdown :trigger="['click']" :disabled="!cubeId" :class="{'disabledStyle':!cubeId}">
                                <div class="ant-dropdown-link" @click.prevent="selectTime">
                                    <span v-if="!selectedTimeList.length">选择时间</span>
                                    <span v-else style="color:#595959">已选择时间（{{selectedTimeList.length}}）</span>
                                    <a-icon type="down" />
                                </div>
                                <div slot="overlay" class="regionList">
                                        <div class="dim-scroll" @click.stop>
                                            <vue-scroll :ops="ops" style="height:100%">
                                                <a-tree
                                                        v-model="checkedKeysTime"
                                                        checkable
                                                        :tree-data="timeList"
                                                        :replaceFields="{title:'dimName',key:'dimCode',children:'children'}"
                                                    />
                                            </vue-scroll>
                                        </div>
                                    </div>
                                </a-dropdown>
                            </div>
                        </a-col>
                    </a-row>
                    <a-row :gutter="[16,16]">
                        <a-col :span="24">
                            <a-row type="flex">
                                <a-col flex="100px" class="label">文件描述</a-col>
                                <a-col flex="auto">
                                    <quill-editor :options="editorOption" :content="fileDesc" @change="onEditorChange" style="height:150px" />
                                </a-col>
                            </a-row>
                        </a-col>
                    </a-row>

                    <div style="height:60px;"></div>   

                    <a-row :gutter="[16,16]">
                        <a-col :span="24">
                            <a-row type="flex">
                                <a-col flex="100px" class="label">文件上传</a-col>
                                <a-col flex="auto">
                                    <a-input style="width:300px" :disabled="true" :value="fileUrl" />
                                    <a-upload
                                        :show-upload-list="false" 
                                        @change="handlePreview" 
                                        :before-upload="handleChangeFile" 
                                        accept=".pdf"
                                    >
                                        <a-button type="primary"  class="upload-btn">选择文件</a-button>
                                    </a-upload>
                                </a-col>
                            </a-row>
                        </a-col>
                    </a-row>                 
                </div>

                <div class="btns">
                    <a-button @click="cancelEdit">取消</a-button>
                    <a-button type="primary" @click="confirmEdit" :disabled="uploading">确定</a-button>
                </div>
            </a-modal>
        </a-layout-content>
    </a-layout>
</template>

<script>
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import { messageTips,getCookie } from '@/until/tools';
import config from '@/until/configs';
import reqwest from 'reqwest';
import { quillEditor } from 'vue-quill-editor';
const allColumns = [
{
    title: '序号',
    customRender: (text, record, index) => `${index + 1}`,
    key: '1',
    align:'center',
  },
  {
    title: '文件名',
    dataIndex: 'fileName',
    key: '2',
  },
  {
    title: '库名称',
    dataIndex: 'cubeName',
    key: '3',
  },
//   {
//     title: '维度名称',
//     dataIndex: 'dimName',
//     key: '4',
//   },
  {
    title: '文件描述',
    dataIndex: 'memo',
    key: '4',
    scopedSlots: {
      customRender: 'memoDesc',
    },
  },
  {
    title: '上传时间',
    dataIndex: 'createTime',
    key: '5',
  },
  {
    title: '更新时间',
    dataIndex: 'updateTime',
    key: '6',
  },
  {
    title: '上传者',
    dataIndex: 'realName',
    key: '7',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    key: '8',
    align:'center',
    scopedSlots: {
      customRender: 'operation',
    },
  },
];
export default {
    name:'Articles',
    components:{quillEditor},
    data() {
        return {
            allColumns,
            newName:'',
            selectedRowKeys:[],
            articles:[],
            keyword:'',
            page:{},
            rowSelection:{
                onChange: (selectedRowKeys) => {
                    this.selectedRowKeys = selectedRowKeys;
                },
                onSelect: (record, selected, selectedRows) => {
                    this.selectedRowKeys = selectedRows.map(item=>item.autoId);
                },
                onSelectAll: (selected, selectedRows) => {
                    this.selectedRowKeys = selectedRows.map(item=>item.autoId)
                },
            },
            uploadUrl:config.inputs_upload_dim_data+'?sid='+getCookie('bd_sid'),
            editorOption: {
                placeholder: "请在这里输入",
                height:200,
                theme:'snow',
                modules:{
                    toolbar:[
                        ['bold', 'italic'],    //加粗，斜体，下划线，删除线
                        ['blockquote'],     //引用，代码块
                        [{ 'header': 1 }, { 'header': 2 }],        // 标题，键值对的形式；1、2表示字体大小
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],     //列表
                        [{ 'indent': '-1'}, { 'indent': '+1' }],     // 缩进
                        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],     //几级标题
                        [{ 'align': [] }],    //对齐方式
                        //['image']    //上传图片、上传视频
                    ]
                }
            },
            fileName:'',
            fileDesc:'',
            waitingEdit:null,
            editVisible:false,
            fileUrl:null,
            waitingUploadFile:null,
            uploading:false,
            dimName:'',
            dimCode:'',
            cubeName:undefined,
            cubeId:'',
            areaList:[],//地区列表
            timeList:[],//时间列表
            IndicatorList:[],//指标列表
            selectedAreaList:[],//已选择地区
            selectedTimeList:[],//已选择时间
            selectedIndicatorList:[],//已选择指标
            checkedKeysArea:[],
            checkedKeysTime:[],
            checkedKeysIndicator:[],
            checkedKeys:[],
            ops: config.vueScrollSetting,
        }
    },
    computed:{
        userInfo() {
            return this.$store.getters.getUserInfo;
        },
        folderId() {
            let id = this.$route.query.folderId;
            if(id) {
                return parseInt(id);
            }
            return null;
        },
        pageSetting(){
			let pageSize=0;
			if(this.page.allSize) {
				pageSize = this.page.allSize;
			}
			return {
				showQuickJumper:true,
				hideOnSinglePage:true,
				showTotal:()=>`共${pageSize}条`,
				total:pageSize,
			}
		},
    },
    watch:{
        folderId(value) {
            if(value) {
                this.getArticleList();
            }
        },
        checkedKeysArea(val) {
            this.selectedAreaList=val.checked
        },
        checkedKeysTime(val) {
            this.selectedTimeList=val.checked
        },
        checkedKeysIndicator(val) {
            this.selectedIndicatorList=val
        },
    },
    mounted() {
        this.getArticleList()
    },
    methods:{
        onLoadData(treeNode,codeName,sheetId) {
            return new Promise(resolve => {
                if (treeNode.dataRef.children) {
                    resolve();
                    return;
                }
                let params = {
                    parentCode:treeNode.eventKey,
                    cubeId:this.cubeId,
                    dimType:codeName,
                    sheetId:sheetId
                }
                this.$http.getDimensionChildren(params).then(res=>{
                    res.dimensionList.forEach(item=>{
                        if(!item.isParent) {
                            item.isLeaf = true;//去掉箭头
                        }
                        if(!item.chkDisabled) {
                            item.disabled = true;//禁掉响应
                        }
                        item['title']=item.dimName
                        item['key']=item.dimCode
                    })
                    treeNode.dataRef.children = res.dimensionList
                    if(codeName=='REGION'){
                        this.areaList = [...this.areaList];
                    }else{
                        this.timeList=[...this.timeList]
                    }
                    resolve();                        
                    });
            });
        },
        //获取地区/时间维度信息
        getDimensions(val){
            let params = {
                cubeId: this.cubeId
            }
            if(val==1){
                this.$http.getDimensions(params).then(res=>{
                    if(res.success) {
                        const data=res.dataletBO.allDimensions.dimensionVOList[2].objList
                        console.log(data,'地区列表');
                        if(data!==undefined){
                            const temp = data.map((item)=>{
                                return item
                            })
                            this.areaList=temp
                        }else{
                            this.areaList=[]
                        }
                    }
                })
            }else if(val==2){
                this.$http.getDimensions(params).then(res=>{
                    if(res.success) {
                        const data=res.dataletBO.allDimensions.dimensionVOList[1].objList
                        console.log(data,'sj列表');
                        if(data!==undefined){
                            const temp = data.map((item)=>{
                                return item
                            })
                            this.timeList=temp
                        }else{
                            this.timeList=[]
                        }
                    }
                })
            }else{
                this.$http.getDimensions(params).then(res=>{
                    if(res.success) {
                        const data=res.dataletBO.allDimensions.dimensionVOList[0].objList
                        console.log(data,'指标列表');
                        if(data!==undefined){
                            const temp = data.map((item)=>{
                                return item
                            })
                            this.IndicatorList=temp
                        }else{
                            this.IndicatorList=[]
                        }
                    }
                })
            }
        },
        //选择地区
        selectArea(){
            this.getDimensions(1)
        },
        //选择指标
        selectIndicator(){
            this.getDimensions(3)
        },
        //选择时间
        selectTime(){
            this.getDimensions(2)
        },
        //选择库
        handleChangeCube(value){
            this.cubeId=value
            this.checkedKeysArea=[]
            this.checkedKeysTime=[]
            this.checkedKeysIndicator=[]
        },
        //获取列表
        getArticleList(obj) {
            let params={}
            if(obj) {
                params={...obj}
            }
            return this.$http.inputs_get_article_newfolder(params).then(res=>{
                if(res.success){
                    this.articles = res.list;
                    this.page = res.page;
                }
                return true;
            })
        },

        setTree(arr,pid,result) {
            arr.forEach(item=>{
                if(item.parentId===pid) {
                    item.children = this.setTree(arr,item.folderId,[]);
                    result.push(item);
                }
            })
            return result;
        },

        onSearch(e) {
            let params = {currentPage:1}
            if(e) {
                params.fileName = e;
            }
            this.getArticleList(params);
        },
        changeTable(e) {
            let params = {currentPage:e.current}
            if(this.keyword) {
                params.fileName = this.keyword;
            }
            this.getArticleList(params);
        },
        cancelEdit() {
            this.editVisible = false;
            this.fileName = '';
            this.fileDesc = '';
            this.waitingEdit = null;
            this.editVisible = false;
            this.fileUrl = null;
            this.waitingUploadFile = null;
        },
        edit(item) {
            this.$http.inputs_get_article_newDetail({autoId:item.autoId}).then(res=>{
                if(res.success) {
                    if(res.dimStorage) {
                        this.editVisible = true;
                        this.waitingEdit = item.autoId;
                        this.fileName = item.fileName;
                        this.fileDesc = item.memo;
                        this.fileUrl = res.dimStorage.filePath;
                        this.dimName=item.dimName;
                        this.dimCode=item.dimCode;
                        this.cubeName=item.cubeName;
                        this.cubeId=item.cubeId;
                    }
                }
            })
        },
        confirmEdit() {
            const formData = new FormData();
            let funName;
            if(this.fileName==='') {
                messageTips('请填写文件名称',2);
                return false;
            }
            if(!this.waitingEdit) {
                if(!this.waitingUploadFile) {
                    messageTips('请上传文件',2);
                    return false;
                }
                // 新增
                funName = config.addDimFile+'?sid='+getCookie('bd_sid');
                // formData.append('dimName', this.dimName);
                formData.append('dimCode', 'INDICATOR:'+this.selectedIndicatorList.join()+',REGION:'+this.selectedAreaList.join()+',TIME:'+this.selectedTimeList.join());
                formData.append('cubeName', this.cubeName);
                formData.append('cubeId', this.cubeId);
                formData.append('file', this.waitingUploadFile);
                formData.append('fileName', this.fileName);//文件名
                formData.append('memo', this.fileDesc);//描述备注
            } else {
                // 修改
                funName = config.updateDimFile+'?sid='+getCookie('bd_sid');                
                formData.append('autoId', this.waitingEdit);//文件id
                formData.append('dimName', this.dimName);
                formData.append('dimCode', this.dimCode);
                formData.append('filePath', this.fileUrl);
                // formData.append('file', this.waitingUploadFile);
                formData.append('fileName', this.fileName);//文件名
                formData.append('memo', this.fileDesc);//描述备注
                formData.append('cubeName', this.cubeName);
                formData.append('cubeId', this.cubeId);
            }
            this.uploading = true;
            reqwest({
                url: funName,
                method: 'post',
                processData: false,
                data: formData,
                contentType : false,
                success: (res) => {
                    this.uploading = false;
                    if(res) {
                        if(res.success) {
                            if(this.waitingEdit) {
                                let params = {currentPage:this.page.currentPage}
                                if(this.keyword) {
                                    params.fileName = this.keyword;
                                }
                                this.getArticleList(params);
                            } else {
                                this.getArticleList();
                            }
                            messageTips(res.message,3);
                            this.editVisible = false;
                            this.fileName = '';
                            this.fileDesc = '';
                            this.fileUrl = null;
                            this.waitingUploadFile = null;
                            this.waitingEdit = null;
                            this.dimCode='',
                            this.dimName='',
                            this.cubeName='',
                            this.cubeId=''
                        } else {
                            messageTips(res.message,1);
                        }                        
                    }
                },
                error: () => {
                
                },
            });
        },
        onEditorChange(e) {
            this.fileDesc = e.html;
        },
        handlePreview({file}) {
            this.fileUrl = file.name;
        },
        handleChangeFile(info) {
            this.waitingUploadFile = info;
        },
        
        deleteArticle(autoId) {
            var that = this;
            this.$confirm({
                title: '提示',
                content: '您确定要删除吗？',
                okText: '确定',
                cancelText: '取消',
                centered:true,
                onOk() {
                    that.$http.inputs_delete_articles({autoIds:autoId}).then(res=>{
                            if(res.success) {
                                messageTips(res.message,3);
                                let params = {currentPage:that.page.currentPage}
                                if(that.keyword) {
                                    params.fileName = that.keyword;
                                }
                                that.getArticleList(params);
                            } else {
                                messageTips(res.message,1);
                            }
                    })
                }
            });
        },
        downloadFile(autoId){
            let url = config.originalDownload+'?autoId='+autoId+'&sid='+getCookie('bd_sid');
            window.open(url, '_blank');
        },
        multiDelete() {
            if(this.selectedRowKeys.length===0) {
                messageTips('请选择要删除的数据',2);
                return false;
            }
            this.deleteArticle(this.selectedRowKeys.join(','));
        }
    }
}
</script>

<style lang="scss" scoped>
    @import '../../scss/articles.scss';
    /deep/.ant-tabs-tab-active{
        color: #FBA951;
        font-size: 16px;
    }
    /deep/.ant-tabs-ink-bar{
        background-color: #FBA951;
    }
</style>